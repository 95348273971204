<template>
	<el-dialog class="dialog" title="新增/编辑" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :rules="rules" :model="formData" label-width="180px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="设备名称：" prop="unitName"><el-input v-model="formData.unitName"></el-input></el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="设备型号：" prop="unitModel"><el-input v-model="formData.unitModel"></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="设备参数：" prop="unitParam"><el-input v-model="formData.unitParam"></el-input></el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="设备厂家：" prop="unitFactory"><el-input v-model ="formData.unitFactory"></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="设备重量：" prop="unitWeight"><el-input v-model="formData.unitWeight"></el-input></el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
var regu = /^[0-9]+\.?[0-9]*$/;
import { dialogMixin } from '@m/dialogMixin';
import configApi from '@config/configApi';
export default {
	mixins: [dialogMixin],
	data() {
		return {
			title: '',
			loading: false,
			formData: {},
			ents: [],
			rules: {
				unitName: [
					{
						required: true,
						message: '请填写设备名称',
						trigger: 'change'
					}
				],
				unitModel: [
					{
						required: true,
						message: '请填写设备型号',
						trigger: 'change'
					}
				],
				unitParam: [
					{
						required: true,
						message: '请填写设备参数',
						trigger: 'change'
					}
				],
				unitFactory: [
					{
						required: true,
						message: '请填写设备厂家',
						trigger: 'change'
					}
				],
				unitWeight:[{
						required: true,
						message: '请填写设备重量',
						trigger: 'change'
					}]
			}
		};
	},
	props: {},
	watch: {},
	methods: {
		//打开弹窗
		open() {
			this.formData = {};
			if (this.dialogObj.type == 2) {
				this.get();
			}
			this.$nextTick(() => {
				this.$refs.form.clearValidate();
			});
		},
		get() {
			this.$get('/backend-api/web/api/v1/eqp-set-unit-param/'+this.dialogObj.row.id).then(res => {
				if (res.code == 1000) {
					this.formData = res.data;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		//提交表单
		submit() {
			if (this.dialogObj.type == 2) {
				this.get();
			}else{
				
			}
			
			
			this.$refs['form'].validate(valid => {
				if (valid) {
					this.loading = true;
					console.log(this.formData);
					if (this.dialogObj.type == 2) {
						this.$putJson('/backend-api/web/api/v1/eqp-set-unit-param', this.formData).then(res => {
							if (res.code == 1000) {
								this.loading = false;
								this.$message.success(res.msg);
								this.$parent.list();
								this.close();
							} else {
								this.loading = false;
								this.$message.error(res.msg);
							}
						});
					}else{
						this.$postData('/backend-api/web/api/v1/eqp-set-unit-param', this.formData).then(res => {
							if (res.code == 1000) {
								this.loading = false;
								this.$message.success(res.msg);
								this.$parent.list();
								this.close();
							} else {
								this.loading = false;
								this.$message.error(res.msg);
							}
						});
					}
					
				} else {
					return false;
				}
			});
		},
		//关闭弹窗
		close() {
			this.visiable = false;
		}
	}
};
</script>
<style lang="scss" scoped>
.dialog {
	::v-deep .cascader {
		display: block;
	}
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
::v-deep input[type='number'] {
	-moz-appearance: textfield;
}
</style>
